import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setHeaderText } from "store/slices/appSlice/AppSlice";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import BaseSearch from "components/shared/baseSearch/BaseSearch";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import {
  useGetCorporateGroupsByUserSelectBoxQuery,
  useGetFacilitiesByUserQuery,
} from "store/apis/ClientPortalAPIs";
import BaseSelectBox from "components/shared/baseSelectBox/BaseSelectBox";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetCurrentUserQuery } from "store/apis/UserApis";
import ArrowPopover from "../facilityPortal/ArrowPopover";
import { EditSkeleton } from "components/admin/admin.overlays";
import { StatesList } from "components/shared/states.canada.constants";
import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";
import useMsalAccount from "utils/useMsalAccount";
import BaseBanner from "components/shared/baseBanner/BaseBanner";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function ClientPortalHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const INDIVIDUAL = "Individual";
  const { userId } = useMsalAccount();
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);

  const handleClick = (event, params) => {
    setPopoverContent(params);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [searchValueDebounced, setSearchValueDebounced] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructing of useform
  const {
    control,
    setValue,
    watch,
    // formState: { errors },
  } = methods;

  const corporateGroupId = watch("corporateGroup");

  const {
    data: facilitiesData,
    isFetching: loading,
    error: hasError,
    isUninitialized: facilitiesUnInitialized,
  } = useGetFacilitiesByUserQuery({
    corporateGroupId: corporateGroupId,
    searchTerm: searchValueDebounced || "",
  });

  const { data: currentUserData } = useGetCurrentUserQuery(userId, {
    skip: !isAuthenticated || !userId,
  });

  const {
    data: corporateGroupsData,
    isUninitialized,
    isFetching: cgLoading,
  } = useGetCorporateGroupsByUserSelectBoxQuery({}, { skip: loading });

  const isSingleCorporateGroup = corporateGroupsData?.length < 3;

  useEffect(() => {
    // Set the header text with corporate group's name
    dispatch(setHeaderText("Client Portal"));
  }, []);

  useEffect(() => {
    if (currentUserData)
      setValue("corporateGroup", currentUserData?.corporateGroupId);
  }, [currentUserData]);

  const facilitiesColumns = [
    {
      field: "name",
      headerName: "Community",
      flex: 4,
      renderCell: (params) => {
        return (
          <Link
            component={"p"}
            to={`/clientPortal/facilityPortal?facilityId=${params.row.id}`}
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "corporateGroup",
      headerName: "Corporate Group",
      renderCell: (params) =>
        params.row.corporateGroup &&
        params.row.corporateGroup != INDIVIDUAL && (
          <Link variant="p"> {params.row.corporateGroup}</Link>
        ),
      flex: 3,
    },
    {
      field: "dietitian",
      headerName: "Consultant",
      renderCell: (params) => (
        <Link variant="p" onClick={(event) => handleClick(event, params)}>
          {params.row.dietitian}
        </Link>
      ),
      flex: 3,
    },
    {
      field: "city",
      headerName: "City",
      flex: 2,
    },
    {
      field: "state",
      headerName: "State",
      flex: 2,
      renderCell: (params) => {
        return StatesList.find((x) => x.id == params.row.state)
          ? StatesList.find((x) => x.id == params.row.state).label
          : params.row.state;
      },
    },
  ];

  useEffect(() => {
    if (facilitiesData?.length == 1 && isSingleCorporateGroup)
      navigate(
        "/clientPortal/facilityPortal?facilityId=" + facilitiesData[0].id
      );
  }, [facilitiesData, corporateGroupsData]);

  return (
    <>
      {(isUninitialized && facilitiesUnInitialized) ||
      !corporateGroupsData ||
      !facilitiesData ? (
        <EditSkeleton />
      ) : (
        <FlexBox
          sx={{
            flexDirection: "column",
          }}
        >
          <BaseBanner
            title={"Client Portal"}
            fontWeight={800}
            color={DMStyles.White}
            bgColor={theme.palette.primary.main}
            marginBottom={"0px"}
            loading={false}
            width={"100%"}
            titleProps={{ variant: "h4" }}
          />
          <div className="actionsRow">
            <div className="leftActions">
              <BaseSearch
                SEARCH_PARAM={SEARCH_PARAM}
                value={searchValue}
                setSearchValue={setSearchValue}
                setDebouncedValue={setSearchValueDebounced}
                setSearchParams={setSearchParams}
              />
              {!isSingleCorporateGroup && (
                <Box
                  sx={{
                    width: "300px",
                  }}
                >
                  <BaseSelectBox
                    control={control}
                    label={"Corporate Group"}
                    endpoint={"getCorporateGroupsByUserSelectBox"}
                    name={"corporateGroup"}
                    size={"small"}
                  />
                </Box>
              )}
            </div>
          </div>
          <BaseDataGrid
            rows={facilitiesData}
            columns={
              isSingleCorporateGroup || corporateGroupId
                ? facilitiesColumns.filter((x) => x.field != "corporateGroup")
                : facilitiesColumns
            }
            loading={loading || cgLoading}
            error={hasError}
            getRowHeight={() => "auto"}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
          <ArrowPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            name={popoverContent?.row?.dietitian}
            contact={popoverContent?.row?.dietitianContact}
            email={popoverContent?.row?.dietitianEmail}
          />
        </FlexBox>
      )}
    </>
  );
}
